import React, { useContext } from "react";
import Helmet from "react-helmet";

import { I18nextProvider } from "react-i18next";
import i18n from "../../i18n/i18n";
import DomainContext from "../../context/DomainContext";


import Disclaimer from "../../components/disclaimer/disclaimer";
 

const DisclaimerMarktDaten = () => {
  const domain = useContext(DomainContext);

  return (
    <I18nextProvider i18n={i18n} defaultNS={"default"}>
      <Helmet>
        <meta content="disclaimer" property="og:title" />
      </Helmet>
      <Disclaimer/>
    </I18nextProvider>
  );
};

export default DisclaimerMarktDaten;
